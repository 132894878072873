import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private breadcrumb: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  breadcrumb$: Observable<any[]> = this.breadcrumb.asObservable();
  breadcrumbKey: string;

  constructor() {
    this.breadcrumbKey = btoa('breadcrumbsData');
    const breadcrumbsData = sessionStorage.getItem(this.breadcrumbKey);
    if (breadcrumbsData) {
      this.breadcrumb.next(JSON.parse(atob(breadcrumbsData)));
    }
  }
  updateBreadcrumbData(newValue:any[]) {
    this.breadcrumb.next(newValue);
    sessionStorage.setItem(this.breadcrumbKey, btoa(JSON.stringify(newValue)));
  }
  getBreadcrumbData(): any[] {
    return this.breadcrumb.value;
  }
}
