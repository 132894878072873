import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { CommanLoginComponent } from './comman-login/comman-login.component';
import { NonAcadamicLoginComponent } from './non-acadamic-login/non-acadamic-login.component';
import { RolesEnum, DashboardRoles } from 'src/app/non-acadamic/config/constant';
import { LoginGuardService } from './login-guard.service';

const routes: Routes = [
  { path: '', component: CommanLoginComponent , canActivate: [():boolean => {
    const userType = sessionStorage.getItem('userLoginType');
    if(userType && userType === 'NonAcademicUserLogin') {
      const user = JSON.parse(sessionStorage.getItem('userProfile')!);
      if (user.role == RolesEnum.user) {         
        window.location.href = '/#/non-academic/survey';          
      } else if(user.role == RolesEnum.admin ){          
        window.location.href = '/#/non-academic/admin';
      } else if(DashboardRoles.includes(user.role)) {
        window.location.href = '/#/non-academic/dashboard';
      }
      return false
    } else return true;
  }]},
  {
    path: 'non-academicLogin',
    component: NonAcadamicLoginComponent,
    canActivate: [():boolean => {
      if(sessionStorage.length > 0){
        sessionStorage.clear();
        window.location.href = '/non-academicLogin'
        return false;
      }
      return true;
    }],
  },
  { path: 'academicLogin', component: LoginComponent },
  { path: 'registration', component: RegistrationComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoginRoutingModule {}
