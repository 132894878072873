import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { CommunicationService } from 'src/app/Service/communication.service';
import { HttpServiceService } from 'src/app/Service/http-service.service';
import { LoginGuardService } from '../login-guard.service';
import { RolesEnum, DashboardRoles } from 'src/app/non-acadamic/config/constant';
import { filter } from 'rxjs';

@Component({
  selector: 'app-non-acadamic-login',
  templateUrl: './non-acadamic-login.component.html',
  styleUrls: ['./non-acadamic-login.component.css']
})
export class NonAcadamicLoginComponent {
  nonAcadamicLogin:any=FormGroup;
  nonAcadamicLoginOTPForm:any = FormGroup;
  submitted:boolean=false;

  isPasswordVisible: boolean = false;
  loginModel: any
  isLoading: boolean = false
  isOtpGenerated: boolean = false;
  enteredOTP: string = '';
  password: string = '';
  numberOfAttempt = 0;
  isNumberUsing = true;
  disabledButton = false;

  constructor(private fb:FormBuilder,  private httpService: HttpServiceService, private router: Router, private communicationService: CommunicationService,private spinner: NgxSpinnerService,
    private messageService: MessageService, private LoginGuardService:LoginGuardService){}

  ngOnInit(){
   this.InitilizeValidation();
  }

  get f(){
    return this.nonAcadamicLogin.controls;
  }

  InitilizeValidation(){
  this.nonAcadamicLogin=this.fb.group({
    mobNumber: ['', [ Validators.required, 
      Validators.pattern('^[0-9]*$'), 
      Validators.minLength(10), 
      Validators.maxLength(10)],
    ],
    email: ['', [ Validators.required, Validators.email ]]
  })
    this.nonAcadamicLoginOTPForm = this.fb.group({
      otp: ['', [Validators.required]]
    });
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  restrictInput(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value + event.key;

    // // Allow only numbers
    if(event.key !== 'Enter') {
      if (!/^\d*$/.test(inputValue)) {
        event.preventDefault();
      }
  
      // Limit to 10 digits
      if (input.value.length >= 10 && event.key !== 'Backspace') {
        event.preventDefault();
      }
    }
  }

  submitForm(){
    this.submitted = true;
    if(this.isNumberUsing) {
      if(this.nonAcadamicLogin.controls.mobNumber.invalid) {
        return;
      }
    } else {
      if(this.nonAcadamicLogin.controls.email.invalid) {
        return;
      }
    }
    this.generateOtp();
  }

  generateOtp() {
    let obj = {};
    let url = '';
    this.disabledButton = true;
    if(this.isNumberUsing) {
      obj = { mobNumber: this.nonAcadamicLogin.value.mobNumber };
      url = 'nonAcademic/auth/login/otp';
    } else {
      obj = { email: this.nonAcadamicLogin.value.email };
      url = 'nonAcademic/auth/login/email';
    }
    this.httpService.generateOTP(url, obj)
    .subscribe({ next: (res) => {
      if(res && res.status === 200) {
        this.disabledButton = false;
        this.numberOfAttempt = 0;
        this.isOtpGenerated = this.isOtpGenerated ? this.isOtpGenerated : !this.isOtpGenerated
        this.messageService.add({ severity: 'success', summary: '', detail: res.data.message });

      }
    }, error: (err) => {
      this.disabledButton = false;
      if (err && err.error && err.error.message) {
        this.messageService.add({ severity: 'error', summary: '', detail: err.error.message });
      } else {
        this.messageService.add({ severity: 'error', summary: '', detail: 'An unexpected error occurred.' });
      }
    }})
  }

  onOtpChange(event: any) {
    if(event) {
      this.enteredOTP = event;      
    }
  }

  showEmail() {
    this.isNumberUsing = !this.isNumberUsing
  }

  verifyOTP() {    
    this.spinner.show();
    this.isLoading = false;
    this.disabledButton = true;
    this.numberOfAttempt = this.numberOfAttempt + 1;
    let obj = {};
    let url = '';
    if(this.isNumberUsing) {
      url = 'nonAcademic/auth/login/otp-verify';
      obj = { mobNumber: this.nonAcadamicLogin.value.mobNumber, otp: this.enteredOTP };
    } else {
      url = 'nonAcademic/auth/login/email-otp-verify';
      obj = { email: this.nonAcadamicLogin.value.email, otp: this.enteredOTP };
    }
    this.httpService.verifyOTP(url, obj)
    .subscribe({ next: (res) => {
      if(res && res.success === 200 && res.result) {
        this.disabledButton = false;
        const user = res.result;
        this.communicationService.setLoginDetails(user);
        this.httpService.setLoggedIn(true);
        if (user.user.role == RolesEnum.user) {         
          this.router.navigateByUrl('/non-academic/survey');          
        } else if(user.user.role == RolesEnum.admin ){          
          this.router.navigateByUrl('/non-academic/admin');
        } else if(DashboardRoles.includes(user.user.role)) {
          this.router.navigateByUrl('/non-academic/dashboard');
        }
        this.isLoading = false;
        this.spinner.hide()
      }
    }, error: (err) => {
      this.disabledButton = false;
      if(err) {
        if(this.numberOfAttempt === 6) {
          this.isOtpGenerated = !this.isOtpGenerated;
          this.messageService.add({ severity: 'error', summary: '', detail: "You have reached maximum limit to very the otp." });
        } else {
          this.messageService.add({ severity: 'error', summary: '', detail: err.error.data.message });
        }
      }
    }})
  }

  login() {
    this.isLoading = true
    this.loginModel = this.nonAcadamicLogin.value;
    this.spinner.show();
    this.httpService.post('nonAcademic/auth/login', this.loginModel).subscribe((data: any) => {
      if (data) {
          this.communicationService.setLoginDetails(data);
          this.httpService.setLoggedIn(true);
          if (data.user.role == "user") {
            this.router.navigateByUrl('/non-academic/survey');
          } 
        }
        this.isLoading = false;
        this.spinner.hide()
      
    }, (error) => {
      this.spinner.hide()
      if(error && error.error) {
        this.messageService.add({ severity: 'error', summary: 'Error-', detail: error.error.message });
      }
      this.isLoading = false;
    })
  }
  

}

