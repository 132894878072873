import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HttpServiceService } from './Service/http-service.service';
import { LevelsEnum } from './non-acadamic/config/constant';
import { CommunicationService } from './Service/communication.service';
import { BreadcrumbService } from './Service/breadcrumb.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'mh-survey';
  isLogIn: boolean = false;

  status: boolean = true;
  statusLink: boolean = true;
  user: any;
  userProfile: any;
  userLevel = LevelsEnum;
  data: any;  

  visible: boolean = false;
  userRoleDetails:any;
  breadcrumbsData :any  = [];
  constructor(private router: Router, private htttpService: HttpServiceService, private communicateservice: CommunicationService , private breadcrumbService: BreadcrumbService) {
    this.user = JSON.parse(sessionStorage.getItem('userProfile')!);
    if (!this.user) {
      this.isLogIn = false;
      this.router.navigate(['']);
    } else {
      this.isLogIn = true;
      this.htttpService.getNonAcedemicUserDetail('nonAcademic/user-role/info?uniqRoleCode', this.user.roleAssingedTo, this.user.centreCode)
        .subscribe((res) => {
          this.userProfile = res;
        })
    }
  }

  ngOnInit() {
    this.breadcrumbService.breadcrumb$.subscribe((breadcrumbsData) => {
      this.breadcrumbsData = breadcrumbsData;
    });
    this.htttpService.getLoggedIn().subscribe((res: boolean) => {
      this.isLogIn = res;
      if (this.isLogIn) {
        this.user = JSON.parse(sessionStorage.getItem('userProfile')!);
        const userRoleDetail = JSON.parse(sessionStorage.getItem('userRoleDetail')!)
        if (userRoleDetail) {
          this.userProfile = userRoleDetail;
        } else {
          this.htttpService.getNonAcedemicUserDetail('nonAcademic/user-role/info?uniqRoleCode', this.user.roleAssingedTo, this.user.centreCode)
            .subscribe((res) => {
              this.userProfile = res;
              this.communicateservice.setUserRoleDetails(res);
            })
        }
      }
    })
    this.htttpService.getData().subscribe(data => {
      this.data = data;

    });
    this.htttpService.dialogVisibility$.subscribe(visible => {
      this.visible = visible;
    });
    
  }

  onSomeAction() {
    // This method gets called on some event, like tab change
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  logout() {
    sessionStorage.clear();
    this.isLogIn = false;
    this.router.navigate(['']);
  }

  showConfirmation() {
    this.htttpService.logout()
  }

  showDialog() {
    this.visible=true
  }

  hideDialog() {
    this.visible=false
  }


}
