import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter } from 'rxjs';
import { HttpServiceService } from '../Service/http-service.service';
import { CommunicationService } from '../Service/communication.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuardService implements CanActivate {

  //  // for confirm dilog
  //  confirmDialog = { display: false };
  //  itemToDelete: any;
  //  userProfile:any
  private routes = {
    dashboard: '/non-academic/dashboard',
    reports: '/non-academic/reports',
    survey: '/non-academic/survey',
    admin: '/non-academic/admin',
    invalid: 'invalid'
  };
  private roles:any = {
    user: [this.routes.survey],
    admin: [this.routes.survey, this.routes.admin],
    supervisor: [this.routes.survey, this.routes.dashboard, this.routes.reports],
    management: [this.routes.dashboard, this.routes.reports],
    auditor: [this.routes.survey, this.routes.dashboard, this.routes.reports, this.routes.admin],
  }
  constructor(private router: Router, private httpService: HttpServiceService, private communicationService: CommunicationService) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this.isTokenExpired()) {
          // this.setNewRefreshToken();
        }
      });
  }
  
  setNewRefreshToken() {
    const token = JSON.parse(sessionStorage.getItem('tokens') || '{}');
    this.httpService.post('auth/refresh-tokens', { refreshToken: token.refresh.token }).subscribe((res: any) => {
      if (res) {
        this.communicationService.setRefreshToken(res);
      }
    })
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    let flag: boolean = false;
    const user = JSON.parse(sessionStorage.getItem('userProfile') || '{}');
    const role: any = route
    const expectedRole: any = role.data.expectedRole;
    const accessibleRoutes = this.roles[user.role].filter((accessibleRoutes:string) => role._routerState.url.indexOf(accessibleRoutes) >= 0)
    
    if (user.role == 'SME' && role._routerState.url.includes('take-survey')) {
      flag = true;
    } else if(accessibleRoutes.length <= 0) {
      flag = false;
      this.router.navigate(['invalid']);
    } else {
      flag = true;
    }
    return flag;
  }

  isTokenExpired(): boolean {
    const token = JSON.parse(sessionStorage.getItem('tokens') || '{}');
    if (token && token.access) {
      const tokenKey = token.access.expires;
      const expirationDate = new Date(tokenKey);
      return expirationDate < new Date();
    }
    return true;
  }



}
