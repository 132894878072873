import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { HttpServiceService } from './http-service.service';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {

  constructor(private httpService: HttpServiceService) { }

  loginDetails: any;

  setLoginDetails(details: any) {
    this.loginDetails = details.user;
    sessionStorage.setItem('userProfile', JSON.stringify(details.user));
    sessionStorage.setItem('tokens', JSON.stringify(details.tokens));
    sessionStorage.setItem('userLoginType', 'NonAcademicUserLogin');
    this.httpService.setTokens();
  }
  
  setUserRoleDetails(data:any){
    sessionStorage.setItem('userRoleDetail', JSON.stringify(data));
  }

  // setRefreshToken(data: any) {
  //   // Retrieve the current tokens from session storage
  //   const currentTokens = JSON.parse(sessionStorage.getItem('tokens') || '{}');
  
  //   // Update the access token object in the current tokens
  //   currentTokens.access = data.token.access;
  
  //   // // Store the updated tokens object back in session storage
  //   sessionStorage.setItem('tokens', JSON.stringify(currentTokens));
  
  //   // // Optionally, you can store the access token separately if needed
  //   // sessionStorage.setItem('access', JSON.stringify(data.token.access));
  
  //   // Call a function to set tokens in the httpService
  //   this.httpService.setTokens();
  // }
  

  setRefreshToken(data: any) {
    sessionStorage.setItem('tokens', JSON.stringify(data));
    this.httpService.setTokens();
  }



  downloadCsv(data: any[], filename: string) {
    const csv = this.convertToCsv(data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const nav: any = navigator;
    if (nav.msSaveBlob) {
      // For Internet Explorer
      nav.msSaveBlob(blob, filename);
    } else {
      // For other browsers
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);

      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  private convertToCsv(data: any[]): string {
    const header = Object.keys(data[0]).join(',') + '\n';
    const rows = data.map(row => Object.values(row).join(',') + '\n');
    return header + rows.join('');
  }

  exportToExcel(data: any[], fileName: string, sheetName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }
}
