export enum LevelsEnum {
    School = "School",
    Cluster = "Cluster",
    Vertex = "Vertex",
    Principal= "Principal"
} 
export const pieChartColor=["#8AB5F0","#988AD0","#5082B7", "#DDD"] //"YES", "NO", "NOT APPLICABLE", "NOT ANSWERED"

export enum RolesEnum {
    admin = "admin",
    user = "user",
    auditor = "auditor",
    supervisor = "supervisor",
    management = "management"
}

export const FrequencyName = {
    daily: "DAILY",
    weekly: "WEEKLY",
    monthly: "MONTHLY",
    yearly: "YEARLY",
    quarterly: "QUARTERLY"
}

export const DashboardRoles = ["auditor","supervisor","management"];
export const SurveyRoles = ["user"];
export const AdminRoles = ["admin"];