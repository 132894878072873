<!-- toster -->
<!-- <p-toast position="bottom-center" [style]="{ width: '800px','font-size': '28px', 'text-align': 'center' }"></p-toast>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
</ngx-spinner> -->
<main class="surface overflow-hidden">
    <div class="container-fluid gx-md-0">
        <div class="row min-vh-100">
            <div class="col-7 d-none d-xl-block">
                <div class="landing-page">
                    <div class="logo-wrapper">
                        <img src="../../../assets/VIBGYOR_logo.svg" class="img-fluid">
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-xl-5">
                <div class="d-flex justify-content-center align-items-center login-card-container module-container">
                    <div class="card justify-content-center align-items-center m-0 me-md-4">
                        <div class="page-content">
                        <h4 class="fw-normal mb-3 text-center">Module Logins</h4>
                        <p role-type="body1">Effortless Access: Seamlessly log in to access your account and unlock a world of possibilities</p>
                        <div class="d-flex gap-4 module-card-container">
                            <a type="button" class="first_btn" routerLink="/non-academicLogin">
                                <div class="main-logo">
                                    <img src="../../../assets/vibgyor-logo.svg" class="img-fluid w-100">
                                </div>
                                <p role-type="body2" class="text-center m-0 lh-sm">Non-Academic Survey (VGOS)</p>                            
                            </a>

                            <a type="button" class="first_btn" href="https://sara.hubblehox.ai/">
                                <div class="main-logo ampersand-logo">
                                    <img src="../../../assets/ampersand-logo.svg" class="img-fluid w-100">
                                </div>
                                <p role-type="body2" class="text-center m-0 lh-sm">General Purpose Survey</p>                            
                            </a>
                        </div>
                    </div>          
                        <p class="footer text-end">Powered by HubbleHox</p>         
                    </div>                        
                </div>
            </div>
        </div>
    </div>
</main>
