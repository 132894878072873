<!-- toster -->
<p-toast></p-toast>
<!-- <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
</ngx-spinner> -->

<main class="surface overflow-hidden">
    <div class="container-fluid gx-md-0">
        <div class="row min-vh-100">
            <div class="col-7 d-none d-xl-block">
                <div class="landing-page login-landing-page">
                    <div class="logo-wrapper">
                        <img src="../../../assets/VIBGYOR_logo.svg" class="img-fluid">
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-xl-5">
                <div class="d-flex justify-content-center justify-content-center align-items-center login-card-container">
                    <div class="card justify-content-center align-items-center m-0 me-md-4">
                        <div class="d-flex align-items-center w-100 vibgyor-logo-container">
                            <div class="d-flex">
                                <i class="bi bi-arrow-left fs-3 align-self-start" (onClick)="isOtpGenerated = !isOtpGenerated" routerLink="/"></i>
                            </div>
                            <div class="w-100 d-flex justify-content-center">                       
                                <div class="user-login-logo">
                                    <img src="../../../assets/vibgyor-logo.svg" class="img-fluid w-100">
                                </div>
                            </div>
                        </div>                        
                        <div class="page-content text-center ">                                         
                            <h4 class="mb-3 mb-lg-4">User Login</h4>
                            <p role-type="body1">Effortless Access: Seamlessly log in to access your account and unlock a world of possibilities</p>
                            <form *ngIf="!isOtpGenerated" class="mt-4" [formGroup]="nonAcadamicLogin" (ngSubmit)="submitForm()">
                                <!-- Mobile Number -->
                                <div class="input-group">
                                    <ng-container *ngIf="isNumberUsing">
                                        <input pInputText type="number" formControlName="mobNumber" class="form-control p-inputtext-sm" placeholder="Enter Mobile Number"
                                            [ngClass]="{ 'is-invalid': submitted && f.mobNumber.errors }" (keypress)="restrictInput($event)"/>
                                            <div class="invalid-feedback" *ngIf="f.mobNumber.invalid && f.mobNumber.errors">
                                                <p role-type="body2" class="text-start" *ngIf="f.mobNumber.errors?.required">Mobile number is required.</p>
                                                <p role-type="body2" class="text-start" *ngIf="f.mobNumber.errors?.pattern">Only numeric values are allowed.</p>
                                                <p role-type="body2" class="text-start" *ngIf="f.mobNumber.errors?.minlength || f.mobNumber.errors?.maxlength">
                                                Mobile number must be exactly 10 digits long.
                                                </p>
                                            </div>                            
                                    </ng-container>
                                    <ng-container *ngIf="!isNumberUsing">
                                        <input pInputText type="email" formControlName="email" class="form-control p-inputtext-sm" placeholder="Enter Email"
                                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }"/>
                                            <div class="invalid-feedback" *ngIf="f.email.invalid && f.email.errors">
                                                <p role-type="body2" class="text-start" *ngIf="f.email.errors?.required">Email is required.</p>
                                                <p role-type="body2" class="text-start" *ngIf="f.email.errors?.email">Invalid Email.</p>
                                            </div>
                                        
                                    </ng-container>
                                </div>
                                <div class="d-flex flex-column gap-3 login-text-container">
                                    <div class="d-flex justify-content-center">
                                        <button [disabled]="disabledButton" pButton [loading]="disabledButton" type="submit" class="btn cta-btn">Generate OTP</button>
                                    </div>
                                    <span>OR</span>      
                                    <div class="login-text">
                                        <a href="javascript:void(0)" (click)="showEmail()">Login using {{!isNumberUsing ? 'Mobile Number' : 'Email'}}</a>
                                    </div>
                                </div>          
                            </form>
                            <form *ngIf="isOtpGenerated" (ngSubmit)="verifyOTP()">
                                <ng-otp-input (onInputChange)="onOtpChange($event)"  [config]="{length:6}"></ng-otp-input>
                                <div class="input-group d-flex justify-content-center">  
                                    <span class="otp-attempt">{{numberOfAttempt}}/6</span>                  
                                    <div class="mt-4 d-flex justify-content-center">
                                        <button pButton [disabled]="numberOfAttempt == 6 || enteredOTP.length != 6 || disabledButton" [loading]="disabledButton" type="submit" class="btn cta-btn">CONTINUE</button>
                                    </div> 
                                    <div class="resend-otp mt-3 d-flex align-items-center gap-3">        
                                        <p role-type="subtitle3">Haven't received OTP yet?</p>
                                        <span (click)="generateOtp()" class="resend">RESEND OTP</span>
                                    </div>
                                </div>
                            </form>
                        </div>          
                    </div>                   
                </div>
            </div>
        </div>
    </div>
</main>
