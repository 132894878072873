<!-- toster -->
<p-toast position="bottom-center" [style]="{ width: '800px','font-size': '28px', 'text-align': 'center' }"></p-toast>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
</ngx-spinner>
<main class="login-container">
    <div class="container-fluid">
        <div class="row vh-100">
            <div class="col-md-6 loginBox">
                <div class="logo">
                    <div class="main-logo">
                    <img src="../../../assets/ampersand-logo.svg">  
                </div>                  
                </div>              

                <div class="d-flex justify-content-center align-items-center login-card-container">
                    <div class="card p-5">
                        <h4 class="fw-normal mb-3 text-center">User Login</h4>
                        <p role-type="body1">Effortless Access: Seamlessly log in to access your account and unlock a world of possibilities</p>
                        <form class="mt-4" [formGroup]="loginForm" (ngSubmit)="submitForm()">

                            <!-- UserName -->
                            <div class="input-group mb-4">
                                <input type="text" id="form3Example3" formControlName="email" class="form-control"
                                    placeholder="abc@ampersandgroup.in"
                                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div class="text-start" *ngIf="f.email.errors.required">Email is required </div>
                                </div>
                            </div>
                            <!-- password -->
                            <div class="input-group">
                                <input type="{{ isPasswordVisible ? 'text' : 'password' }}" id="form3Example3"
                                    formControlName="password" class="form-control" placeholder="password"
                                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                    <div class="text-start" *ngIf="f.password.errors.required">Password is required</div>
                                </div>
                                <i class="eye-icon"  (click)="togglePasswordVisibility()"
                                    [ngClass]="{'bi-eye': !isPasswordVisible, 'bi-eye-slash': isPasswordVisible}"
                                    [ngStyle]="submitted && f.password.errors?{'display': 'none'} : {'display': ''}"></i>
                            </div>
        
                                <div class="mt-4 d-flex justify-content-center">
                                    <button type="submit" class="btn btn-primary-type">Login</button>
                                </div>
                                <div class="d-flex justify-content-center mt-2">
                                    <a class="nav-link-type" routerLink="/">Back</a>
                                </div>
                        </form>
                    </div>
                </div>

            </div>

            <div class="col-md-6 image-box d-none d-md-block">
                <img src="../../../assets/icons/loginPageImg.jpg">
            </div>
        </div>

    </div>
</main>
