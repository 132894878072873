import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-comman-login',
  templateUrl: './comman-login.component.html',
  styleUrls: ['./comman-login.component.css']
})
export class CommanLoginComponent {

  selectedDepartment:any=''

  constructor(private router:Router){

  }

  ngOnInit(){}

  goToLoginPage(){
    if(this.selectedDepartment){
    if(this.selectedDepartment == 'Acadamic Survey'){
      this.router.navigateByUrl('/academicLogin')
    }
    else {
      this.router.navigateByUrl('/non-academicLogin')
    }
  }
    
  }


}
