import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HttpServiceService {
  private dataSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});

//   apiURL = 'http://35.207.203.197:3000/v1/';  //upladed client IP
  // apiURL = 'http://localhost:3000/v1/'; // local
  // apiURL = 'http://143.110.179.113:3000/v1/'; // productionn
  // apiURL = 'http://192.168.0.158:3008/v1/';       //Local wifi
  // apiURL = 'http://35.207.244.52::3000/v1/'; // client
 // apiURL ='https://sara-uat.hubblehox.ai/v1/' // uat
      apiURL ='https://schoolaudit.hubblehox.ai/v1/' // new product 

  token = '';
  headerToken: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
    this.setTokens();
  }
  downloadFile(url:string): Observable<Blob> {
    return this.http.get(this.apiURL + url, {
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type':
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }),
    }).pipe(catchError(this.handleError.bind(this)));
  }
  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    this.spinner.hide(); // Ensure the spinner is stopped on any error

    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      if (error.status === 401) {
        this.showDialog();
        console.log('error 401');
      } else if (error.status === 400) {
        errorMessage = error.error.message;
      } else {
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
    }
    return throwError(errorMessage);
  }

  post(url: string, data: any): Observable<any> {
    if (url.includes('refresh-tokens')) {
      return this.http
        .post<any>(this.apiURL + url, data)
        .pipe(catchError(this.handleError.bind(this)));
    } else {
      return this.http
        .post<any>(this.apiURL + url, data, { headers: this.headerToken })
        .pipe(catchError(this.handleError.bind(this)));
    }
  }

  patch(url: string, data: any): Observable<any> {
    const type = Object.getPrototypeOf(data);
    let id: any;
    if (type.append) {
      const stringifyId = JSON.stringify(Object.fromEntries(data));
      const parseId = JSON.parse(stringifyId);
      id = parseId.id;
      data.delete('id');
    } else {
      id = data.id;
      delete data.id;
    }
    return this.http
      .patch<any>(this.apiURL + url + '/' + id, data, {
        headers: this.headerToken,
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  get(url: string): Observable<any> {
    return this.http
      .get<any>(this.apiURL + url, { headers: this.headerToken })
      .pipe(catchError(this.handleError.bind(this)));
  }

  getById(url: string, id: string): Observable<any> {
    return this.http
      .get<any>(this.apiURL + url + '/' + id, { headers: this.headerToken })
      .pipe(catchError(this.handleError.bind(this)));
  }

  delete(url: string, id: any): Observable<any> {
    return this.http
      .delete<any>(this.apiURL + url + '/' + id, { headers: this.headerToken })
      .pipe(catchError(this.handleError.bind(this)));
  }

  setTokens() {
    const token = JSON.parse(sessionStorage.getItem('tokens') || '{}');
    if (token && token.access) {
      this.token = token.access.token;
      this.headerToken = new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      });
    }
  }

  // communication set up for login
  private isLoggedInSubject = new Subject<boolean>();

  setLoggedIn(value: boolean): void {
    this.isLoggedInSubject.next(value);
  }

  getLoggedIn(): Subject<boolean> {
    return this.isLoggedInSubject;
  }

  getNonAcedemicUserDetail(
    url: string,
    roleCode: string,
    centreCode: string
  ): Observable<any> {
    return this.http
      .get<any>(`${this.apiURL}${url}=${roleCode}&centreCode=${centreCode}`, {
        headers: this.headerToken,
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  getNonAcedemicSchoolDetails(
    url: string,
    cluster: string,
    level: string
  ): Observable<any> {
    return this.http
      .get<any>(`${this.apiURL}${url}=${cluster}&level=${level}`, {
        headers: this.headerToken,
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  getSchoolDrilledDetails(payload: any): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiURL}dashboard/drill-down/school?centreCode=${payload.centreCode}&from=${payload.from}&to=${payload.to}&frequency=${payload.frequency}&level=${payload.level}`,
        { headers: this.headerToken }
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  getDepartmentDrilledDetails(payload: any): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiURL}dashboard/drill-down/department?centreCode=${payload.centreCode}&from=${payload.from}&to=${payload.to}&frequency=${payload.frequency}&department=${payload.department}&level=${payload.level}`,
        { headers: this.headerToken }
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  getSubDepartmentDrilledDetails(payload: any): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiURL}dashboard/drill-down/sub-department?centreCode=${payload.centreCode}&from=${payload.from}&to=${payload.to}&frequency=${payload.frequency}&department=${payload.department}&subDepartment=${payload.subDepartment}&level=${payload.level}`,
        { headers: this.headerToken }
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  getSubSubDepartmentDrilledDetails(payload: any): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiURL}dashboard/drill-down/sub-sub-department?centreCode=${payload.centreCode}&from=${payload.from}&to=${payload.to}&frequency=${payload.frequency}&department=${payload.department}&subDepartment=${payload.subDepartment}&subSubDepartment=${payload.subSubDepartment}&level=${payload.level}`,
        { headers: this.headerToken }
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  getCategoryDrilledDetails(payload: any): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiURL}dashboard/drill-down/category?centreCode=${payload.centreCode}&from=${payload.from}&to=${payload.to}&frequency=${payload.frequency}&department=${payload.department}&subDepartment=${payload.subDepartment}&subSubDepartment=${payload.subSubDepartment}&category=${payload.category}&level=${payload.level}`,
        { headers: this.headerToken }
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  getSchoolScoreCount(payload: any): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiURL}dashboard/school/answer/score?schoolId=${payload.centreCode}&cluster=${payload.cluster}&from=${payload.from}&to=${payload.to}&frequency=${payload.frequency}&level=${payload.level}`,
        { headers: this.headerToken }
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  getDepartmentScoreCount(payload: any): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiURL}dashboard/department/answer/score?schoolId=${payload.centreCode}&cluster=${payload.cluster}&from=${payload.from}&to=${payload.to}&frequency=${payload.frequency}&level=${payload.level}&departmentCode=${payload.departmentCode}`,
        { headers: this.headerToken }
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  getSubDepartmentScoreCount(payload: any): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiURL}dashboard/sub-department/answer/score?schoolId=${payload.centreCode}&cluster=${payload.cluster}&from=${payload.from}&to=${payload.to}&frequency=${payload.frequency}&level=${payload.level}&departmentCode=${payload.departmentCode}&subDepartmentCode=${payload.subDepartmentCode}`,
        { headers: this.headerToken }
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  getSubSubDepartmentScoreCount(payload: any): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiURL}dashboard/sub-sub-department/answer/score?schoolId=${payload.centreCode}&cluster=${payload.cluster}&from=${payload.from}&to=${payload.to}&frequency=${payload.frequency}&level=${payload.level}&departmentCode=${payload.departmentCode}&subDepartmentCode=${payload.subDepartmentCode}&subSubDepartmentCode=${payload.subSubDepartmentCode}`,
        { headers: this.headerToken }
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  getCategoryScoreCount(payload: any): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiURL}dashboard/category/answer/score?schoolId=${payload.centreCode}&cluster=${payload.cluster}&from=${payload.from}&to=${payload.to}&frequency=${payload.frequency}&level=${payload.level}&departmentCode=${payload.departmentCode}&subDepartmentCode=${payload.subDepartmentCode}&subSubDepartmentCode=${payload.subSubDepartmentCode}&category=${payload.category}`,
        { headers: this.headerToken }
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  verifyOTP(url: string, data: any): Observable<any> {
    return this.http.post<any>(`${this.apiURL}${url}`, data, {
      headers: this.headerToken,
    });
  }

  generateOTP(url: string, data: any): Observable<any> {
    return this.http.post<any>(`${this.apiURL}${url}`, data, {
      headers: this.headerToken,
    });
  }

  getScoreSummaryData(payload: any): Observable<any> {
    let url=this.apiURL+'dashboard/school-summary?centreCode='+payload.centreCode+'&cluster='+payload.cluster+'&from='+payload.from+'&to='+payload.to+'&frequency='+payload.frequency+'&level='+payload.level;
    if(payload?.departmentCode && payload.departmentCode!=='undefined'){
      url+='&departmentCode='+payload.departmentCode;
      }
    if(payload.subDepartmentCode && payload.subDepartmentCode!=='undefined'){
      url+='&subDepartmentCode='+payload.subDepartmentCode;
     }
    if(payload.subSubDepartmentCode && payload.subSubDepartmentCode!=='undefined'){
      url+='&subSubDepartmentCode='+payload.subSubDepartmentCode;
    }
    return this.http
      .get<any>(url,
        { headers: this.headerToken }
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  getAuditSubmittionCount(payload: any): Observable<any> {
    let url=this.apiURL+'dashboard/audit-count-summary?centreCode='+payload.centreCode+'&cluster='+payload.cluster+'&from='+payload.from+'&to='+payload.to+'&frequency='+payload.frequency+'&level='+payload.level;
    if(payload?.departmentCode && payload.departmentCode!=='undefined'){
      url+='&departmentCode='+payload.departmentCode;
      }
    if(payload.subDepartmentCode && payload.subDepartmentCode!=='undefined'){
      url+='&subDepartmentCode='+payload.subDepartmentCode;
     }
    if(payload.subSubDepartmentCode && payload.subSubDepartmentCode!=='undefined'){
      url+='&subSubDepartmentCode='+payload.subSubDepartmentCode;
    }
    return this.http
      .get<any>(url,{ headers: this.headerToken }
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  setData(data: any): void {
    this.dataSubject.next(data);
  }

  getData(): Observable<any> {
    return this.dataSubject.asObservable();
  }

  // for pop-up
  private dialogVisibilitySource = new BehaviorSubject<boolean>(false);
  dialogVisibility$ = this.dialogVisibilitySource.asObservable();

  showDialog() {
    this.dialogVisibilitySource.next(true);
  }

  hideDialog() {
    this.dialogVisibilitySource.next(false);
  }

  logout() {
    const data = JSON.parse(sessionStorage.getItem('tokens') || '{}');
    const token = {
      refreshToken: data.refresh.token,
    };
    this.post('auth/logout', token).subscribe(
      (res: any) => {
        this.completeLogout();
        this.hideDialog();
      },
      (error: any) => {
        this.completeLogout();
      }
    );
  }

  private completeLogout() {
    this.setLoggedIn(false);
    sessionStorage.clear();
    this.router.navigate(['/non-academicLogin']);
  }
}
