<main class="main-container" *ngIf="isLogIn">
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
        <div class="offcanvas-body">
            <aside class="sidebarMenu">
            <div class="sideBarUst d-flex justify-content-center">
                <div class="logo">
                    <img src="../assets/vibgyor-logo.svg">                
                </div>
            </div>
            <hr class="opacity-100 ms-3 me-3 m-0" style="border-top: 1px solid #864f20;">

            <div class="sideBarOrta mt-3">

                    <!-- superadmin -->
                    <ul class="nav flex-column ">
                        <li class="nav-item nav-dashboard" *ngIf="user.role == 'superadmin'" data-bs-dismiss="offcanvas">
                            <a class="nav-link " routerLink="superadmin/survey-detail" routerLinkActive="active"
                                href="javascript:void(0)">
                                <i class="pi pi-microsoft mb-0"></i>
                                <span class="lables">Dashboard</span>
                            </a>
                        </li>
                        <li class="nav-item" *ngIf="user.role == 'superadmin'" data-bs-dismiss="offcanvas">
                            <a class="nav-link" routerLink="superadmin/new-survey-admin" routerLinkActive="active"
                                href="javascript:void(0)">
                                <i class="bi bi-person-circle mb-0"></i>
                                <span class="lables">User Management</span>
                            </a>
                        </li>
                        <!-- surveyadmin -->
                        <li class="nav-item nav-dashboard" *ngIf="user.role == 'surveyadmin'" data-bs-dismiss="offcanvas">
                            <a class="nav-link" routerLink="surveyadmin/survey-dashboard" routerLinkActive="active"
                                href="javascript:void(0)">
                                <i class="pi pi-microsoft mb-0"></i>
                                <span class="lables">Dashboard</span>
                            </a>
                        </li>
                        <li class="nav-item" *ngIf="user.role == 'surveyadmin'" data-bs-dismiss="offcanvas">
                            <a class="nav-link" routerLink="surveyadmin/survey_assign" routerLinkActive="active"
                                href="javascript:void(0)">
                                <i class="bi bi-bookmarks-fill h4 mb-0"></i>
                                <span class="lables">Survey Admin View</span>
                            </a>
                        </li>
                        <li class="nav-item" *ngIf="user.role == 'surveyadmin'" data-bs-dismiss="offcanvas">
                            <a class="nav-link" [routerLink]="['/surveyadmin/add-survey-questions', 'new']"
                                routerLinkActive="active">
                                <i class="bi bi-bookmark-plus-fill mb-0"></i>
                                <span class="lables">Create Survey Form</span>
                            </a>
                        </li>
                        <li class="nav-item" *ngIf="user.role == 'surveyadmin'" data-bs-dismiss="offcanvas">
                            <a class="nav-link" routerLink="surveyadmin/view-survey-form" routerLinkActive="active">
                                <i class="bi bi-chat-right-text mb-0"></i>
                                <span class="lables">View Survey Forms</span>
                            </a>
                        </li>
                        <li class="nav-item" data-bs-toggle="collapse" href="#collapseExample" role="button"
                            *ngIf="user.role == 'superadmin'" data-bs-dismiss="offcanvas">
                            <a class="nav-link">
                                <i class="bi bi-chat-right-text mb-0"></i>
                                <span class="lables">Master Management</span>
                            </a>
                        </li>
                        <div class="collapse collapsed-dropdown" id="collapseExample">
                            <li class="nav-item" data-bs-dismiss="offcanvas">
                                <a class="nav-link text-start" routerLink="superadmin/block" routerLinkActive="active"
                                    href="javascript:void(0)">
                                    <span class="lables">Block</span>
                                </a>
                            </li>
                            <li class="nav-item" data-bs-dismiss="offcanvas">
                                <a class="nav-link text-start" routerLink="superadmin/district"
                                    routerLinkActive="active" href="javascript:void(0)">
                                    <span class="lables">District</span>
                                </a>
                            </li>
                            <li class="nav-item" data-bs-dismiss="offcanvas">
                                <a class="nav-link text-start" routerLink="superadmin/division"
                                    routerLinkActive="active" href="javascript:void(0)">
                                    <span class="lables">Division</span>
                                </a>
                            </li>
                            <li class="nav-item" data-bs-dismiss="offcanvas">
                                <a class="nav-link text-start" routerLink="superadmin/school" routerLinkActive="active"
                                    href="javascript:void(0)">
                                    <span class="lables">School</span>
                                </a>
                            </li>
                        </div>
                        <!-- Block coordinator -->
                        <li class="nav-item" *ngIf="user.role == 'block'" data-bs-dismiss="offcanvas">
                            <a class="nav-link" routerLink="block-coordinator/projects" routerLinkActive="active"
                                href="javascript:void(0)">
                                <i class="bi bi-person-add h4 mb-0"></i>
                                <span class="lables" *ngIf="user.role == 'block'">Block Coordinator</span>
                            </a>
                        </li>
                        <!-- SME COORDINATOR -->
                        <li class="nav-item" *ngIf="user.role == 'SME'" data-bs-dismiss="offcanvas">
                            <a class="nav-link" routerLink="sme-coordinator/project" routerLinkActive="active" href="javascript:void(0)">
                                <i class="bi bi-person-add h4 mb-0"></i>
                                <span class="lables">SME Coordinator</span>
                            </a>
                        </li>
                        <!-- District Coordinator -->
                        <li class="nav-item" *ngIf="user.role == 'district'" data-bs-dismiss="offcanvas">
                            <a class="nav-link" routerLink="district-coordinator/project" routerLinkActive="active"
                                href="javascript:void(0)">
                                <i class="bi bi-person-add h4 mb-0"></i>
                                <span class="lables">District Coordinator</span>
                            </a>
                        </li>
                        <!-- Division Coordinator -->
                        <li class="nav-item" *ngIf="user.role == 'division'" data-bs-dismiss="offcanvas">
                            <a class="nav-link" routerLink="division-coordinator/project" routerLinkActive="active"
                                href="javascript:void(0)">
                                <i class="bi bi-person-add h4 mb-0"></i>
                                <span class="lables">Division Coordinator</span>
                            </a>
                        </li>
           
                        <!-- non academic -->
                        <li class="nav-item" *ngIf="['auditor','supervisor','management'].includes(user.role)" data-bs-dismiss="offcanvas">
                            <a class="nav-link" routerLink="non-academic/dashboard" routerLinkActive="active" href="javascript:void(0)">
                                <div class="nav-item-img dashboard-icon"></div>
                                <span class="lables">Dashboard</span>
                            </a>
                        </li>

                        <li class="nav-item" *ngIf="['auditor','supervisor','admin','user'].includes(user.role)" data-bs-dismiss="offcanvas">
                            <a class="nav-link" routerLink="non-academic/survey" routerLinkActive="active" href="javascript:void(0)">
                                <div class="nav-item-img survey-icon"></div>
                                <span class="lables">Survey</span>
                            </a>
                        </li>
                       
                        <!-- admin Part -->
                        <li class="nav-item" *ngIf="['admin','auditor'].includes(user.role)" data-bs-dismiss="offcanvas">
                            <a class="nav-link" routerLink="non-academic/admin" routerLinkActive="active" href="javascript:void(0)">
                                <div class="nav-item-img admin-icon"></div>
                                <span class="lables">Admin</span>
                            </a>
                        </li>

                        <li class="nav-item" *ngIf="['auditor','supervisor','management'].includes(user.role)" data-bs-dismiss="offcanvas">
                            <a class="nav-link" routerLink="non-academic/reports" routerLinkActive="active" href="javascript:void(0)">
                                <div class="nav-item-img report-icon"></div>
                                <span class="lables">Reports</span>
                            </a>
                        </li>
                         <!-- end nav Item -->
                          <div class="logout-container">
                         <li class="nav-item"  data-bs-dismiss="offcanvas">
                            <a class="nav-link" href="../assets/document/Frequently Asked Questions (FAQs).pdf" routerLinkActive="active" target="_blank">
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="help 1" clip-path="url(#clip0_3265_2308)">
                                        <g id="g3447">
                                            <g id="g3449">
                                                <mask id="mask0_3265_2308" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                                                    <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
                                                </mask>
                                                <g mask="url(#mask0_3265_2308)">
                                                    <path class="nav-link-paths" id="path3459" d="M9.82422 17.623C10.4662 20.858 13.3198 23.2969 16.7434 23.2969C18.159 23.2969 19.477 22.8796 20.5817 22.1619L23.459 22.9577L22.6631 20.0804C23.3809 18.9757 23.7981 17.6578 23.7981 16.2422C23.7981 12.8186 21.3593 9.96497 18.1242 9.32297" stroke="#666666" stroke-width="1.5" stroke-miterlimit="10"/>
                                                    <path class="nav-link-paths" id="path3463" d="M9.66406 0.703126C4.99123 0.703126 1.20312 4.49124 1.20312 9.16406C1.20312 10.8393 1.69011 12.4007 2.53002 13.7147L1.54227 17.2859L5.11339 16.2981C6.42744 17.1381 7.98884 17.625 9.66406 17.625C14.3369 17.625 18.125 13.8369 18.125 9.16406C18.125 4.49124 14.3369 0.703126 9.66406 0.703126Z" stroke="#666666" stroke-width="1.5" stroke-miterlimit="10"/>
                                                    <path class="nav-link-paths" id="path3465" d="M10.3672 14.1094H8.96094V12.7031H10.3672V14.1094Z" fill="#666666"/>
                                                    <path class="nav-link-paths" id="path3469" d="M7.55469 7.03125C7.55469 5.86627 8.49908 4.92188 9.66406 4.92188C10.829 4.92188 11.7734 5.86627 11.7734 7.03125C11.7734 7.64766 11.5089 8.20223 11.0873 8.58788L9.66406 9.89063V11.2969" stroke="#666666" stroke-width="1.5" stroke-miterlimit="10"/>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                <span class="lables">FAQs</span>
                            </a>
                        </li>

                        <li class="nav-item" data-bs-dismiss="offcanvas">
                            <a class="nav-link" href="../assets/document/User Manual.pdf" routerLinkActive="active" target="_blank">
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="open-book 1" clip-path="url(#clip0_3265_2327)">
                                        <path id="Vector" d="M23.6946 4.91885L22.0122 4.91877V3.17728C22.0122 2.77857 21.7205 2.43983 21.3262 2.38084C21.2573 2.37049 19.6184 2.13243 17.6448 2.49154C15.4447 2.89186 13.69 3.87909 12.5 5.37153C11.3098 3.87909 9.55516 2.89186 7.35509 2.49154C5.38144 2.13251 3.74249 2.37049 3.67373 2.38084C3.27938 2.43991 2.98767 2.77865 2.98767 3.17728V4.91877H1.30533C0.860645 4.91877 0.5 5.27941 0.5 5.72418V20.9003C0.5 21.1611 0.626222 21.4057 0.838827 21.5568C1.05143 21.7079 1.324 21.7467 1.57022 21.6609C1.63066 21.6399 7.66563 19.5803 12.1657 21.633C12.3781 21.7299 12.6219 21.7299 12.8343 21.633C17.3218 19.5861 23.3697 21.6401 23.4298 21.6609C23.5161 21.6909 23.6055 21.7057 23.6945 21.7057C23.8596 21.7057 24.023 21.655 24.1612 21.5569C24.3738 21.4057 24.5 21.1611 24.5 20.9004V5.72426C24.4999 5.27949 24.1394 4.91885 23.6946 4.91885ZM2.11074 19.8227V6.52951H2.98767V17.2548C2.98767 17.4888 3.09005 17.711 3.2671 17.864C3.44415 18.017 3.67963 18.0856 3.91106 18.0514C3.9555 18.0449 7.45416 17.5646 10.0103 19.3184C6.86159 18.78 3.71025 19.4028 2.11074 19.8227ZM11.6946 18.5921C10.5688 17.5749 9.1024 16.8871 7.35509 16.5692C6.5142 16.4161 5.73408 16.3715 5.11025 16.3715C4.92504 16.3715 4.75356 16.3755 4.59849 16.3817V3.91683H4.59841C6.14628 3.84701 9.91168 4.01251 11.6946 7.03496V18.5921ZM13.3053 7.03504C15.0818 4.02552 18.8531 3.85251 20.4015 3.91885V16.3817C19.7241 16.3544 18.7353 16.3707 17.6448 16.5692C15.8975 16.8871 14.4311 17.5748 13.3053 18.5921V7.03504ZM14.987 19.3189C17.5434 17.5641 21.0439 18.0449 21.0876 18.0513C21.3196 18.0862 21.5549 18.018 21.7325 17.8651C21.9101 17.712 22.0122 17.4893 22.0122 17.2548V6.52959H22.8892V19.8227C21.2892 19.4026 18.1365 18.7796 14.987 19.3189Z" fill="#666666"/>
                                    </g>
                                </svg>                                
                                <span class="lables">Manual</span>
                            </a>
                        </li>                       

                        <li class="nav-item">
                            <a class="nav-link">
                                <i class="pi pi-sign-out icons h4 mb-0" title="Logout" (click)="logout()"></i>
                                <span class="lables">Logout</span>
                            </a>
                        </li>
                    </div>                       
                    </ul>
                </div>
            </aside>
        </div>
    </div>

    <!-- Page Content -->
    <div class="page-container" id="content">
        <!-- Navbar -->
        <nav class="navbar navbar-expand-xl navbar-light py-0">
            <div class="d-flex align-items-center justify-content-between w-100 px-0 px-md-2 px-xl-4">
                <div class="d-flex align-items-center">
                    <button class="navbar-toggler d-xl-none" type="button" data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar"
                        aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    
                    <div class="d-none d-lg-flex align-items-center">
                        <nav aria-label="breadcrumb">
                            <ol class="d-flex justify-content-center justify-content-center breadcrumb breadcrumb-style m-0">
                                <li class="d-flex justify-content-center justify-content-center breadcrumb-list-item" *ngFor="let breadcrumb of breadcrumbsData;index as bindex">
                                    <a href="javascript:void(0)">{{breadcrumb}}</a>
                                    <svg class="mx-2" *ngIf="bindex < (breadcrumbsData.length-1)" fill="#000000" height="10px" width="10px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xml:space="preserve" stroke="#000000" stroke-width="16.5"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001 c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213 C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606 C255,161.018,253.42,157.202,250.606,154.389z"></path> </g></svg>
                                </li>
                                <!-- <li [ngClass]="{'active': data.name && !data.department && !data.subdepartment}" class="breadcrumb-list-item" aria-current="page">
                                    <a href="javascript:void(0)">{{data.name}}</a></li> -->
                            </ol>
                        </nav>
                        <!-- <nav aria-label="breadcrumb">
                            <ol class="breadcrumb m-0">
                                <li [ngClass]="{'active': data.name && data.department  }" class="breadcrumb-list-item">
                                    <a href="javascript:void(0)" class="active-department" *ngIf="data.department">
                                        <span>{{data.department}}</span>
                                    </a>
                                </li>
                                <li [ngClass]="{'active': data.name && data.department && data.subdepartment }" class="breadcrumb-list-item">
                                    <a href="javascript:void(0)" *ngIf="data.department">
                                        {{ (data.subdepartment === undefined || data.subdepartment === null || data.subdepartment === '' || 
                                        data.subdepartment.trim().toUpperCase() === 'NA') ? '' : ' - ' + data.subdepartment || '' }}     
                                                                      
                                    </a>
                                </li>
                                <li [ngClass]="{'active': data.name && data.department && data.subdepartment  && data.subsubdepartment }" class="breadcrumb-list-item">
                                    <a href="javascript:void(0)" *ngIf="data.department">
                                         {{ (data.subsubdepartment === undefined || data.subsubdepartment === null || data.subsubdepartment === '' || 
                                         data.subsubdepartment.trim().toUpperCase() === 'NA') ? '' : '- ' + data.subsubdepartment || '' }}                                 
                                    </a>
                                </li>
                            </ol>
                        </nav> -->
                    </div>                   
                </div>
                <div class="collapse navbar-collapse justify-content-end" id="navbarTogglerDemo01">
                    <ul class="navbar-nav flex-row">
                        <li class="nav-item"
                            *ngIf="user.role == 'block'||user.role == 'division'||user.role == 'district'||user.role=='SME'">
                            <h6>{{user.name}}</h6>
                        </li>
                        <li class="vr" *ngIf="user.role == 'block'||user.role == 'division'||user.role == 'district'||user.role=='SME'"></li>
                        <!-- <li class="nav-item" > 
                            <h6>{{user.roleAssingedTo}}</h6>
                        </li> -->
                        <li class="nav-item d-none">
                            <i class="bi bi-bell-fill icons" title="Notifications"></i>
                        </li>
                        <li class="nav-item header">
                            <div class="d-flex flex-row align-items-center">
                                <img class="m-2" src="../assets/icons/Icon.png" title="Update Project">
                                <div class="d-flex flex-column">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <span  class="user-name"> {{ user.name }} </span>
                                        <img style="cursor: pointer;" (click)="op.toggle($event)" src="../assets/icons/fi_chevron-down.png" title="Show Profile">
                                    </div>
                                    <span class="user-desc"> {{ user?.uniqRoleName }} </span>
                                </div>
                                <p-overlayPanel #op>
                                    <div>
                                        <div class="d-flex flex-column gap-3 user-profile-dropdown">
                                            <h6 class="font-medium">{{ user.name }}</h6>  
                                            <span>{{ user.email }}</span>                                            
                                            <span *ngIf="[userLevel.School, userLevel.Principal].includes(user.level); else otherLevelUser">{{ userProfile?.locationData?.displayName }} ({{ userProfile?.locationData?.cluster }}) </span>
                                            <ng-template #otherLevelUser>
                                                <span>{{ user?.cluster }} </span>
                                            </ng-template>
                                            <span>{{ user?.uniqRoleName }}</span>
                                        </div>
                                    </div>
                                </p-overlayPanel>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

        <router-outlet></router-outlet>

        <footer>
            <span>Powered by HubbleHox</span>
        </footer>
    </div>
</main>

<div *ngIf="!isLogIn">
    <router-outlet></router-outlet>
</div>
<div class="flex justify-content-center">
    <!-- <button pButton type="button" (click)="showDialog()" label="Show" class="d-none"></button> -->
    <p-dialog header="" [modal]="true" [(visible)]="visible" [style]="{ width: '40rem' }">
      <h5>Your Session has expired. Please login again to continue.</h5>
      <button type="button" class="float-end mt-4" pButton (click)="showConfirmation()" (click)="hideDialog()" label="OK"></button>
    </p-dialog>
  </div>