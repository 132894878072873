import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxOtpInputModule } from 'ngx-otp-input';
import { RegistrationComponent } from './registration/registration.component';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CommanLoginComponent } from './comman-login/comman-login.component';
import { NonAcadamicLoginComponent } from './non-acadamic-login/non-acadamic-login.component';
import { MessageService } from 'primeng/api';
import { RippleModule } from 'primeng/ripple';
import { NgOtpInputModule } from  'ng-otp-input';
import { ButtonModule } from 'primeng/button';

@NgModule({
  declarations: [
     LoginComponent,
     RegistrationComponent,
     CommanLoginComponent,
     NonAcadamicLoginComponent,
      ],
  imports: [
    CommonModule,
    LoginRoutingModule,
    ReactiveFormsModule,
    NgxOtpInputModule,
    InputTextModule,
    ToastModule,
    RippleModule,
    ButtonModule,
    NgxSpinnerModule,
    FormsModule,
    NgOtpInputModule
  ],
  providers: [MessageService]
})
export class LoginModule { }
